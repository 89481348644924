import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams  } from 'react-router-dom';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded'; // Import imagesLoaded
import config from '../../config';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import { clear } from '@testing-library/user-event/dist/clear';
function NewsIsotope() {
  const [jsonData, setJsonData] = useState([]);
  const [jsonDataId, setJsonDataId] = useState([]);
  const [activeButton, setActiveButton] = useState('*');
  const isotopeRef = useRef(null);
  const iso = useRef(null);
  const { id } = useParams();
  const [isId, setIsId] = useState(false);

  async function fetchData() {
    try {
      // Check if ID exists
      if (id) {
        // If ID exists, fetch data based on the ID
        setIsId(true);
        const response = await axios.get(config.API_URL_LIVE + config.NEWS_URL + '/' + id);
        if (response.status === 200) {
          setJsonDataId(response.data.data);          
        } else {
          console.error('Unexpected status code:', response.status);
        }
      } else {
        // If ID doesn't exist, fetch all data
        setIsId(false);
        const response = await axios.get(config.API_URL_LIVE + config.NEWS_URL);
        if (response.status === 200) {
          setJsonData(response.data.data);
        } else {
          console.error('Unexpected status code:', response.status);
        }
      }
    } catch (error) {
      console.error('Error reading JSON file:', error);
    }
  }
  useEffect(() => {
    // async function fetchData() {
    //   try {
    //     const response = await axios.get(config.API_URL_LIVE + config.NEWS_URL);
    //     if(response.status === 200){
    //       setJsonData(response.data.data);
    //  }
    //  else {
    //      console.error('Unexpected status code:', response.status);
    //  }          
    // } catch (error) {
    //     console.error('Error reading JSON file:', error);
    // }
    // };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (jsonData.length > 0 && isotopeRef.current) {
      // Initialize Isotope when jsonData is available
      iso.current = new Isotope(isotopeRef.current, {
        itemSelector: '.grid-item',
        layoutMode: 'fitRows',
      });

      // Use imagesLoaded to wait for images to load
      const imgLoad = imagesLoaded(isotopeRef.current);
      imgLoad.on('progress', () => {
        iso.current.layout(); // Trigger Isotope layout after images are loaded
      });

      // Cleanup Isotope instance when the component unmounts
      return () => {
        if (iso.current) {
          iso.current.destroy();
        }
      };
    }
  }, [jsonData]);

  const uniqueTypes = [...new Set(jsonData.map((item) => item.country))];

  const filter = (filterValue) => {
    if (iso.current) {
      iso.current.arrange({ filter: filterValue });
    }
    setActiveButton(filterValue);
  };

  return (
    <div>
      <section className="portfolio-area">
        <div className="container">
          {id ? (
            <div className='text-center'>
              <img src={config.FILE_URL + jsonDataId.thumb} alt='' className='img-fluid '/>
              <div> {jsonDataId.title}</div>
              <div>{jsonDataId.content}</div>  
              <div className='text-start my-3'><Link to={'../news'} className='btn btn-theme btn-sm'> <i class="fa-solid fa-arrow-left"></i> Back</Link></div>
            </div>
          ) : (
            
            <div>
              <div className="iso-nav">
              <ul>
                <li
                  className={activeButton === '*' ? 'active' : ''}
                  onClick={() => filter('*')}
                >
                  All
                </li>
                {uniqueTypes.map((country) => (
                  <li
                    key={country}
                    className={
                      activeButton === `.${country.replace(/\s+/g, '')}` ? 'active' : ''
                    }
                    onClick={() => filter(`.${country.replace(/\s+/g, '')}`)} 
                  >
                    {country}
                  </li>
                ))}
              </ul>
              </div>
              <div className="main-iso row grid" ref={isotopeRef}>
                {Array.from(jsonData).length > 0 && jsonData.length > 0 && jsonData.map((item) => (
                  <div
                    key={item.id}
                    className={`item grid-item personal ${item.country.replace(/\s+/g, '')} post col-md-4 col-sm-6`}
                  >
                    <div className='px-3'>
                      <div className='overlay-area'>
                      <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto"> 
                          <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid ' />
                      </SlideshowLightbox>
                        {/* <div className="content-overlay"></div>
                          <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">{item.title}</h3>
                          </div> */}
                      </div>
                      <div className="content-details fadeIn-bottom">
                        <h6 className="content-title"><Link to={`../news/${item.id}`} onClick={() => fetchData()}>{item.title}</Link></h6>
                      </div>
                    </div>                
                  </div>
                ))}
              </div>
              </div>
          )}
          
          
          <div className="text-center">
            <div className='pt-5'>
              <Link to="../resume" className="btn btn-talk">
                  Send Your CV
              </Link>
            </div>
            {/* <h3 className='pt-5'>The valued Employer once decides to use our service; followings are the prerequisites that should be prepared by the Employer</h3> */}
          </div>
        </div>
      </section>    
  </div>
  );
}

export default NewsIsotope;
