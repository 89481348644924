import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded'; // Import imagesLoaded
import config from '../../config';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
function IsotopeDemo() {
  const [jsonData, setJsonData] = useState([]);
  const [activeButton, setActiveButton] = useState('*');
  const [loading, setLoading] = useState(true); // State to track loading status
  const isotopeRef = useRef(null);
  const iso = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(config.API_URL_LIVE + config.PORTFOLIO_URL);
        if (response.status === 200) {
          setJsonData(response.data.data);
        } else {
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        console.error('Error reading JSON file:', error);
      } finally {
        // Set loading to false after data fetching is complete
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (jsonData.length > 0 && isotopeRef.current) {
      // Initialize Isotope when jsonData is available
      iso.current = new Isotope(isotopeRef.current, {
        itemSelector: '.grid-item',
        layoutMode: 'fitRows',
      });

      // Use imagesLoaded to wait for images to load
      const imgLoad = imagesLoaded(isotopeRef.current);
      imgLoad.on('progress', () => {
        iso.current.layout(); // Trigger Isotope layout after images are loaded
      });

      // Cleanup Isotope instance when the component unmounts
      return () => {
        if (iso.current) {
          iso.current.destroy();
        }
      };
    }
  }, [jsonData]);

  const uniqueTypes = [...new Set(jsonData.map((item) => item.country))];

  const filter = (filterValue) => {
    if (iso.current) {
      iso.current.arrange({ filter: filterValue });
    }
    setActiveButton(filterValue);
  };

  return (
    <div>
      <section className="portfolio-area">
        <div className="container">        
          <div className="iso-nav">
            <ul>
              <li
                className={activeButton === '*' ? 'active' : ''}
                onClick={() => filter('*')}
              >
                All
              </li>
              {uniqueTypes.map((country) => (
                <li
                  key={country}
                  className={
                    activeButton === `.${country.replace(/\s+/g, '')}` ? 'active' : ''
                  }
                  onClick={() => filter(`.${country.replace(/\s+/g, '')}`)} 
                >
                  {country}
                </li>
              ))}
            </ul>
          </div>
          {loading && <div>Loading...</div>}
        {/* Isotope container */}
          <div className="main-iso row grid" ref={isotopeRef}>
            {jsonData.length > 0 && jsonData.map((item) => (
              <div
                key={item.id}
                className={`item grid-item personal ${item.country.replace(/\s+/g, '')} post col-md-4 col-sm-6`}
              >
                <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                  <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid px-3' />
                </SlideshowLightbox>
              </div>
            ))}
          </div>
        </div>
      </section>    
  </div>
  );
}

export default IsotopeDemo;
